import { apiInstance } from "./apiMiddleware";

export const getWhyChooseUsCards = (limit, page) => {
  let params = {};
  if (limit) params.limit = limit;
  if (page) params.page = page;
  const api = apiInstance();
  return api.get("api/speciality/get-all", { params });
};

export const postWhyChooseUsCards = (data) => {
  const api = apiInstance();
  return api.post("api/speciality/post", data);
};

export const deleteWhyChooseUsCards = (data) => {
  const api = apiInstance();
  return api.post("api/speciality/delete", data);
};
