import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import InputField from "../../components/InputField/InputField";
import Select from "react-select";
import "animate.css";
import Header from "../../components/header/Header";
import { Pricing } from "../../SVGS";
import { PRIMARY } from "../../constants/Colors";
import { ErrorCode, ErrorMessages } from "../../constants/ErrorCodes";
import { postPropertyPrices } from "../../api/propertyDetails";
import { Enums } from "../../constants/Enums";

export default function PropertyPricingAddEdit({ isEdit }) {
  const icon = () => {
    return <Pricing size="25" fill={PRIMARY} />;
  };

  const navigate = useNavigate();
  const location = useLocation();
  const prevData =
    location.state && location.state ? location.state.editData : null;
  const page = location.state && location.state ? location.state.page : null;

  const [isLoading, setIsLoading] = useState(false);
  const [storeyErr, setStoreyErr] = useState(false);
  const [amountErr, setamountErr] = useState(false);
  const [quantityErr, setquantityErr] = useState(false);
  const [typeErr, settypeErr] = useState(false);
  const [descriptionErr, setdescriptionErr] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState("");
  const [typeOptions, settypeOptions] = useState([
    { value: 1, label: "bedroom" },
    { value: 2, label: "bathroom" },
  ]);
  const [cleaningTypeOptions, setCleaningTypeOptions] = useState(
    Enums.cleaning_type_list
  );
  const [err, setErr] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [newData, setNewData] = useState({
    id: prevData ? prevData.id : 0,
    type:
      prevData && prevData.bedroom > 0
        ? { value: 1, label: "bedroom" }
        : { value: 2, label: "bathroom" },
    cleaning_type:
      prevData && prevData.cleaning_type == Enums.cleaning_type.deep
        ? { value: 2, label: "Deep/Vacate" }
        : { value: 1, label: "Standard" },
    storey: prevData ? prevData.storey : "",
    bedroom: prevData ? prevData.bedroom : "",
    bathroom: prevData ? prevData.bathroom : "",
    quantity: prevData
      ? prevData?.bedroom > 0
        ? prevData?.bedroom
        : prevData?.bathroom
      : "",
    price: prevData ? prevData.price : "",
  });

  // fetching initial data
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewData({ ...newData, [name]: value });
  };

  const saveOnclick = () => {
    if (isValidData()) {
      let obj = {
        id: newData.id,
        storey: newData.storey,
        bedroom: newData.type.value == 1 ? newData.quantity : 0,
        bathroom: newData.type.value == 2 ? newData.quantity : 0,
        cleaning_type: newData.cleaning_type.value,
        price: newData.price,
        room: 1,
      };
      setErr(false);
      setIsLoading(true);
      postPropertyPrices(obj)
        .then(({ data }) => {
          setIsLoading(false);
          if (
            data.error_code == ErrorCode.success ||
            data.error_code == ErrorCode.updated
          ) {
            navigate("/dashboard/property-pricing", {
              state: { page: page },
            });
          } else if (data.error_code == ErrorCode.failed) {
            setErr(true);
            setErrMsg(ErrorMessages.failed);
          } else {
            setErr(true);
            setErrMsg("Oops some error occured. EC:" + data.error_code);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          setErr(true);
          setErrMsg(ErrorMessages.network_error);
        });
    }
  };

  function isValidData() {
    if (!newData.storey) setStoreyErr(true);
    else if (!newData.quantity || newData.quantity == 0) setquantityErr(true);
    else if (!newData.price || newData.price == 0) setamountErr(true);
    else return true;
    return false;
  }

  return (
    <>
      <div className="mainDashView">
        <div>
          <Header svg={icon} DashboardNavText={"Property Prices"} />
        </div>
        <div className="dashPanel" style={{ position: "relative" }}>
          <>
            <div className="ar-dashpanel">
              {err ? (
                <div
                  style={{
                    textAlign: "center",
                    color: "red",
                    position: "absolute",
                    top: "7px",
                    fontSize: "0.95rem",
                    width: "90%",
                  }}
                >
                  <p>{errMsg}</p>
                </div>
              ) : null}

              <div>
                <div className="ae-formFields">
                  <div className="ae-inputField">
                    <InputField
                      name={"storey"}
                      type="number"
                      isErr={storeyErr}
                      errorMsg={"Please fill in the field"}
                      value={newData?.storey}
                      onChange={(e) => {
                        setStoreyErr(false);
                        handleChange(e);
                      }}
                      cat={"Storey"}
                      placeholder={"No. of stories"}
                      radius="7px"
                      width="400px"
                    />
                  </div>
                  <div
                    className="ae-inputField"
                    style={{ position: "relative", marginLeft: "25px" }}
                  >
                    <div className="ar-selectText">Type</div>
                    <Select
                      options={typeOptions}
                      placeholder={"Select type"}
                      value={newData.type}
                      className="ae-select"
                      onChange={(data) => {
                        handleChange({
                          target: { name: "type", value: data },
                        });
                      }}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          width: "310px",
                          border: `1px solid lightgray`,
                          borderColor: "lightgray",
                          //   marginLeft: "4px",
                          marginRight: "0px",
                          borderRadius: "8px",
                          fontSize: "14px",
                          padding: "0px 13px",
                        }),
                      }}
                    />
                  </div>
                </div>

                <div className="ae-formFields">
                  <div className="ae-inputField">
                    <InputField
                      name={"quantity"}
                      type="number"
                      isErr={quantityErr}
                      errorMsg={"Please fill in the field"}
                      value={newData?.quantity}
                      onChange={(e) => {
                        setquantityErr(false);
                        handleChange(e);
                      }}
                      cat={"Quantity"}
                      placeholder={"Enter Quantity"}
                      radius="7px"
                      width="400px"
                    />
                  </div>
                  <div className="ae-inputField" style={{ marginLeft: "25px" }}>
                    <InputField
                      name={"price"}
                      type="number"
                      isErr={amountErr}
                      errorMsg={"Please fill in the field"}
                      value={newData?.price}
                      onChange={(e) => {
                        setamountErr(false);
                        handleChange(e);
                      }}
                      cat={"Amount (AUD)"}
                      placeholder={"Enter amount in AUD"}
                      radius="7px"
                      width="310px"
                    />
                  </div>
                </div>

                <div className="ae-formFields">
                  <div
                    className="ae-inputField"
                    style={{ position: "relative" }}
                  >
                    <div className="ar-selectText">Cleaning</div>
                    <Select
                      options={cleaningTypeOptions}
                      placeholder={"Select cleaning type"}
                      value={newData.cleaning_type}
                      className="ae-select"
                      onChange={(data) => {
                        handleChange({
                          target: { name: "cleaning_type", value: data },
                        });
                      }}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          width: "310px",
                          border: `1px solid lightgray`,
                          borderColor: "lightgray",
                          //   marginLeft: "4px",
                          marginRight: "0px",
                          borderRadius: "8px",
                          fontSize: "14px",
                          padding: "0px 13px",
                        }),
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="addEdit-btns">
                <div className="ev-btnAdd ev-btn">
                  <button
                    className="editPage_btn"
                    style={{ backgroundColor: PRIMARY, color: "white" }}
                    disabled={isLoading}
                    onClick={saveOnclick}
                  >
                    {isLoading ? <LoadingSpinner /> : isEdit ? "Update" : "Add"}
                  </button>
                </div>
                <div className="ev-btnDel ev-btn">
                  <button
                    className="editPage_btn"
                    style={{ color: PRIMARY }}
                    disabled={isLoading}
                    onClick={() => {
                      navigate("/dashboard/property-pricing", {
                        state: { page: page },
                      });
                    }}
                  >
                    {isEdit ? "Discard" : "Back"}
                  </button>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </>
  );
}
