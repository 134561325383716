import { apiInstance } from "./apiMiddleware";

export const getDiscounts = () => {
  const api = apiInstance();
  return api.get("api/discount/get-all");
};

export const postDiscount = (data) => {
  const api = apiInstance();
  return api.post("api/discount/post", data);
};

export const deleteDiscount = (data) => {
  const api = apiInstance();
  return api.post("api/discount/delete", data);
};
