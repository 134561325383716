import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import InputField from "../../components/InputField/InputField";
import Select from "react-select";
import "animate.css";
import "./discount.css";
import Header from "../../components/header/Header";
import { HEADINGS_LIGHT, PRIMARY } from "../../constants/Colors";
import { ErrorCode, ErrorMessages } from "../../constants/ErrorCodes";
import { Enums } from "../../constants/Enums";
import { deleteDiscount, getDiscounts, postDiscount } from "../../api/discount";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FiEdit } from "react-icons/fi";
import ModalComp from "../../components/modal/ModalComp";
import VatrateSVG from "../../SVGS/VatrateSVG";

export default function Discount() {
  const icon = () => {
    return <VatrateSVG size="25" fill={PRIMARY} />;
  };

  const navigate = useNavigate();
  const location = useLocation();
  const prevData =
    location.state && location.state ? location.state.editData : null;
  const page = location.state && location.state ? location.state.page : null;

  const [isEdit, setisEdit] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [textErr, settextErr] = useState(false);
  const [typeErr, setTypeErr] = useState(false);
  const [amountErr, setamountErr] = useState(false);
  const [typeOptions] = useState([
    { value: Enums.discount_type.property, label: "Property Discount" },
    { value: Enums.discount_type.other, label: "Other Discount" },
  ]);
  const [data, setData] = useState([]);
  const [discountData, setDiscountData] = useState({
    id: 0,
    discount_type: "",
    discount_text: "",
    discount: "",
  });

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    fetchDiscount();
  }, []);

  function fetchDiscount() {
    setIsRefreshing(true);
    getDiscounts()
      .then(({ data }) => {
        setIsRefreshing(false);
        if (data.error_code === ErrorCode.success) {
          setData(data.result);
        } else {
          toast.warn("Some error occured");
        }
      })
      .catch((err) => {
        setIsRefreshing(false);
        toast.error("Network Error!");
      });
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "discount_text") settextErr(false);
    else if (name === "discount_type") setTypeErr(false);
    else setamountErr(false);
    setDiscountData((prev) => ({ ...prev, [name]: value }));
  };

  const saveOnclick = () => {
    if (isValidData()) {
      setIsLoading(true);
      postDiscount(discountData)
        .then(({ data }) => {
          setIsLoading(false);
          if (
            data.error_code == ErrorCode.success ||
            data.error_code == ErrorCode.updated
          ) {
            toast.success("Updated successfully!");
            fetchDiscount();
          } else {
            toast.warn("Some error occured");
          }
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error("Network Error!");
        });
    }
  };

  function isValidData() {
    if (!discountData.discount_text) settextErr(true);
    else if (!discountData.discount_type) setTypeErr(true);
    else if (!discountData.discount || discountData.discount == 0)
      setamountErr(true);
    else return true;
    return false;
  }

  function setIsDeleted(id) {
    let arr = [...data.filter((item) => item.id != id)];
    setData([...arr]);
  }

  function setEditData(editData) {
    setisEdit(true);
    setDiscountData(() => ({
      id: editData?.id,
      discount_text: editData?.discount_text,
      discount_type:
        editData?.discount_type === Enums.discount_type.property
          ? typeOptions[0]
          : typeOptions[1],
      discount: editData?.discount,
    }));
  }

  return (
    <>
      <div className="mainDashView">
        <div>
          <Header svg={icon} DashboardNavText={"Property Prices"} />
        </div>
        <div className="dashPanel" style={{ position: "relative" }}>
          <>
            <ToastContainer
              autoClose={2000}
              hideProgressBar={true}
              position="bottom-right"
            />
            <div className="discount_view_container">
              <div className="tableContainer">
                <table className="roleViewTable">
                  <thead>
                    <tr>
                      <th>Discount Type</th>
                      <th>Text</th>
                      <th>Discount %</th>
                      <th style={{ paddingRight: "20px" }}>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isRefreshing ? (
                      <tr>
                        <td colSpan={4}>
                          <LoadingSpinner />
                        </td>
                      </tr>
                    ) : data.length === 0 ? (
                      <tr>
                        <td
                          colSpan={4}
                          rowSpan={2}
                          style={{
                            fontSize: "1.4rem",
                            fontWeight: "600",
                            color: HEADINGS_LIGHT,
                            fontStyle: "italic",
                          }}
                        >
                          No discounts available
                        </td>
                      </tr>
                    ) : (
                      data?.map(
                        (data, index) =>
                          index < 2 && (
                            <Rows
                              data={data}
                              page={page}
                              setIsDeleted={setIsDeleted}
                              setEditData={setEditData}
                            />
                          )
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            {/* update  */}
            <div className="ar-dashpanel">
              <div>
                <div className="ae-formFields">
                  <div className="ae-inputField">
                    <InputField
                      name={"discount_text"}
                      type="text"
                      isErr={textErr}
                      errorMsg={"Please fill in the field"}
                      value={discountData?.discount_text}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      cat={"Discount Text"}
                      placeholder={"Write text to shoe on page"}
                      radius="7px"
                      width="400px"
                    />
                  </div>
                  <div
                    className="ae-inputField"
                    style={{ position: "relative", marginLeft: "25px" }}
                  >
                    <div className="ar-selectText">Type</div>
                    <Select
                      options={typeOptions}
                      placeholder={"Select type"}
                      value={discountData.discount_type}
                      className="ae-select"
                      onChange={(data) => {
                        handleChange({
                          target: { name: "discount_type", value: data },
                        });
                      }}
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          width: "310px",
                          border: `1px solid ${typeErr ? "red" : "lightgray"}`,
                          borderColor: typeErr ? "red" : "lightgray",
                          marginRight: "0px",
                          borderRadius: "8px",
                          fontSize: "14px",
                          padding: "0px 13px",
                        }),
                      }}
                    />
                    {typeErr && (
                      <span
                        style={{
                          position: "absolute",
                          right: "0",
                          bottom: "-14px",
                          color: "red",
                          fontSize: "0.8rem",
                        }}
                      >
                        required*
                      </span>
                    )}
                  </div>
                </div>

                <div className="ae-formFields">
                  <div className="ae-inputField">
                    <InputField
                      name={"discount"}
                      type="number"
                      isErr={amountErr}
                      errorMsg={"Please fill in the field"}
                      value={discountData?.discount}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      cat={"Discount (%)"}
                      placeholder={"Enter discount"}
                      radius="7px"
                      width="400px"
                    />
                  </div>
                </div>
              </div>

              <div className="addEdit-btns">
                <div className="ev-btnAdd ev-btn">
                  <button
                    className="editPage_btn"
                    style={{
                      backgroundColor: PRIMARY,
                      color: "white",
                      marginTop: "35px",
                    }}
                    disabled={isLoading}
                    onClick={saveOnclick}
                  >
                    {isLoading ? <LoadingSpinner /> : isEdit ? "Update" : "Add"}
                  </button>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </>
  );
}

const Rows = ({ data, page, setIsDeleted, setEditData = () => {} }) => {
  const navigate = useNavigate();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [delErr, setdelErr] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const deleteOnclick = () => {
    setModalIsOpen(true);
  };

  const deleteClick = () => {
    setdelErr(false);
    let obj = {
      id: data ? data.id : null,
    };
    setIsLoading(true);
    deleteDiscount(obj)
      .then(({ data }) => {
        setIsLoading(false);
        if (data.error_code == ErrorCode.success) {
          setModalIsOpen(false);
          setIsDeleted(obj?.id);
        } else if (data.error_code == ErrorCode.failed) {
          setdelErr(true);
          setErrMsg(ErrorMessages.failed);
        } else {
          setdelErr(true);
          setErrMsg(data.message);
        }
      })
      .catch(() => {
        setErrMsg(ErrorMessages.network_error);
        setdelErr(true);
        setIsLoading(false);
      });
  };

  return (
    <>
      <ModalComp
        isVisible={modalIsOpen}
        isCaution
        setIsVisible={() => {
          setdelErr(false);
          setModalIsOpen(false);
        }}
        isErr={delErr}
        errMsg={errMsg}
        onClick={deleteClick}
        isLoading={isLoading}
        msg={"Are you sure you want to delete?"}
      />
      {data ? (
        <tr>
          <td className="break-line-170">
            {data.discount_type === Enums.discount_type.property
              ? "Property"
              : "Others"}
          </td>
          <td className="break-line-170">
            {data?.discount_text?.length > 84
              ? data?.discount_text?.substring(0, 80) + "..."
              : data?.discount_text}
          </td>
          <td className="break-line-170">{data?.discount}</td>

          <td
            style={{
              display: "flex",
              justifyContent: "center",
              minWidth: "60px",
            }}
          >
            <button
              onClick={() => {
                setEditData(data);
              }}
              className="Actionbtn editBtn"
            >
              <FiEdit />
            </button>
            <button onClick={deleteOnclick} className="Actionbtn delBtn">
              <RiDeleteBin6Line />
            </button>
          </td>
        </tr>
      ) : null}
    </>
  );
};
