import React from "react";
import { X } from "react-feather";
import "./uploadImg.css";
import { BASE_URL } from "../../constants/ConstantVariable";

export default function UploadThumbnail({
  label,
  imagePath,
  imgFile,
  onSelectImage = () => {},
  onRemove = () => {},
  error = "",
}) {
  return (
    <div className="file-input" style={{ position: "relative" }}>
      <div className="input__box__label" style={{ marginTop: "10px" }}>
        {label}
      </div>
      <div
        className="Add_Client_Image_container"
        style={{ border: error ? "1px solid red" : "" }}
      >
        <label className="changeimage_label">
          {imagePath || imgFile ? (
            <>
              <img
                alt="cp"
                className="add_image_style"
                src={
                  imagePath
                    ? `${BASE_URL + imagePath}`
                    : URL.createObjectURL(imgFile)
                }
              />
              <div>
                <input
                  type="file"
                  className="formuploadimage_input"
                  accept=".png, .jpg, jpeg"
                  onChange={(e) => {
                    onSelectImage(e.target.files[0]);
                  }}
                  onClick={(event) => (event.currentTarget.value = null)}
                />
              </div>
            </>
          ) : (
            <div className="AddimageBtn">
              <span className="cam_Image_upload">+</span>
              <div>
                <input
                  type="file"
                  className="formuploadimage_input"
                  accept=".png, .jpg, jpeg"
                  onChange={(e) => {
                    onSelectImage(e.target.files[0]);
                  }}
                  onClick={(event) => (event.currentTarget.value = null)}
                />
              </div>
            </div>
          )}
        </label>
        <div
          style={{ display: imagePath || imgFile ? "" : "none" }}
          className="images_cross"
          onClick={() => onRemove()}
        >
          <X size={30} />
        </div>
      </div>
      {error && <div className="thumbnail_err">{error}</div>}
    </div>
  );
}
