import React from "react";

export default function Dashboard(props) {
  return (
    <svg
      width={props.size ? props.size : "19"}
      height={props.size ? props.size : "19"}
      viewBox={props.viewBox ? props.viewBox : "0 -3 19 19"}
      fill={props.fill ? props.fill : "white"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.8533 12.7298V2.63885H1.71697V12.7298H16.8533ZM16.8533 0.957031C17.2994 0.957031 17.7272 1.13422 18.0426 1.44962C18.358 1.76503 18.5352 2.1928 18.5352 2.63885V12.7298C18.5352 13.1758 18.358 13.6036 18.0426 13.919C17.7272 14.2344 17.2994 14.4116 16.8533 14.4116H10.967V16.0934H12.6488V17.7752H5.92152V16.0934H7.60334V14.4116H1.71697C0.783565 14.4116 0.0351562 13.6548 0.0351562 12.7298V2.63885C0.0351562 1.70544 0.783565 0.957031 1.71697 0.957031H16.8533ZM3.39879 4.32067H10.967V8.52521H3.39879V4.32067ZM11.8079 4.32067H15.1715V6.00249H11.8079V4.32067ZM15.1715 6.8434V11.0479H11.8079V6.8434H15.1715ZM3.39879 9.36612H6.76243V11.0479H3.39879V9.36612ZM7.60334 9.36612H10.967V11.0479H7.60334V9.36612Z"
        fill={props.fill ? props.fill : "white"}
      />
    </svg>
  );
}
