import { apiInstance } from "./apiMiddleware";

export const getAllExtraPrices = (limit, page, search) => {
  let params = {};
  if (page) params.page = page;
  if (limit) params.limit = limit;
  if (search) params.search = search;
  const api = apiInstance();
  return api.get("api/extras-detail/get-all", { params });
};

export const postExtraPrice = (data) => {
  const api = apiInstance();
  return api.post("api/extras-detail/post", data);
};

export const deleteExtraPrice = (data) => {
  const api = apiInstance();
  return api.post("api/extras-detail/delete", data);
};
