import { apiInstance } from "./apiMiddleware";

export const getOtherPrices = (limit, page, search) => {
  let params = {};
  if (search) params.search = search;
  if (limit) params.limit = limit;
  if (page) params.page = page;
  const api = apiInstance();
  return api.get("api/carpet-cleaning-detail/get-all", { params });
};

export const postOtherPrices = (data) => {
  const api = apiInstance();
  return api.post("api/carpet-cleaning-detail/post", data);
};

export const deleteOtherPrices = (data) => {
  const api = apiInstance();
  return api.post("api/carpet-cleaning-detail/delete", data);
};
