import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import InputField from "../../components/InputField/InputField";
import Select from "react-select";
import "animate.css";
import Header from "../../components/header/Header";
import { Pricing } from "../../SVGS";
import { PRIMARY } from "../../constants/Colors";
import { ErrorCode, ErrorMessages } from "../../constants/ErrorCodes";
import { Enums } from "../../constants/Enums";
import { postExtraPrice } from "../../api/extras";
import { postOtherPrices } from "../../api/otherApi";

export default function OtherPricingAddEdit({ isEdit }) {
  const icon = () => {
    return <Pricing size="25" fill={PRIMARY} />;
  };

  const navigate = useNavigate();
  const location = useLocation();
  const prevData =
    location.state && location.state ? location.state.editData : null;
  const page = location.state && location.state ? location.state.page : null;

  const [isLoading, setIsLoading] = useState(false);
  const [priceErr, setPriceErr] = useState(false);
  const [typeOptions, settypeOptions] = useState(Enums.others_list);
  const [err, setErr] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [newData, setNewData] = useState({
    id: prevData ? prevData.id : 0,
    carpet_type: prevData
      ? Enums.others_list.find((item) => item.value == prevData.carpet_type)
      : Enums.others_list[0],
    lounge_carpet: prevData
      ? Enums.lounge_carpet_list[prevData.lounge_carpet - 1]
      : Enums.lounge_carpet_list[0],
    room_carpet: prevData
      ? Enums.room_carpet_list[prevData.room_carpet - 1]
      : Enums.room_carpet_list[0],
    couch: prevData
      ? Enums.couch_list[prevData.couch - 1]
      : Enums.couch_list[0],
    mattress: prevData
      ? Enums.mattress_list[prevData.mattress - 1]
      : Enums.mattress_list[0],
    cleaning_type: prevData
      ? Enums.cleaning_type_list[prevData.cleaning_type - 1]
      : Enums.cleaning_type_list[0],
    price: prevData ? prevData.price : 1,
  });

  // fetching initial data
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewData({ ...newData, [name]: value });
  };

  const saveOnclick = () => {
    if (isValidData()) {
      let quantity =
        newData.carpet_type?.value === Enums.other_type.lounge_carpet
          ? newData.lounge_carpet
          : newData.carpet_type?.value === Enums.other_type.room_carpet
          ? newData.room_carpet
          : newData.carpet_type?.value === Enums.other_type.couch
          ? newData.couch
          : newData.mattress;

      let name =
        newData.carpet_type?.value === Enums.other_type.lounge_carpet
          ? "lounge_carpet"
          : newData.carpet_type?.value === Enums.other_type.room_carpet
          ? "room_carpet"
          : newData.carpet_type?.value === Enums.other_type.couch
          ? "couch"
          : "mattress";

      let obj = {
        id: newData.id,
        carpet_type: newData.carpet_type.value,
        cleaning_type: newData.cleaning_type.value,
        price: newData.price,
        lounge_carpet: name == "lounge_carpet" ? quantity.value : 0,
        room_carpet: name == "room_carpet" ? quantity.value : 0,
        couch: name == "couch" ? quantity.value : 0,
        mattress: name == "mattress" ? quantity.value : 0,
      };
      setErr(false);
      setIsLoading(true);
      postOtherPrices(obj)
        .then(({ data }) => {
          setIsLoading(false);
          if (
            data.error_code == ErrorCode.success ||
            data.error_code == ErrorCode.updated
          ) {
            navigate("/dashboard/other", {
              state: { page: page },
            });
          } else if (data.error_code == ErrorCode.failed) {
            setErr(true);
            setErrMsg(ErrorMessages.failed);
          } else {
            setErr(true);
            setErrMsg("Oops some error occured. EC:" + data.error_code);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          setErr(true);
          setErrMsg(ErrorMessages.network_error);
        });
    }
  };

  function isValidData() {
    if (!newData.price || newData.price < 0) setPriceErr(true);
    else return true;
    return false;
  }

  return (
    <>
      <div className="mainDashView">
        <div>
          <Header svg={icon} DashboardNavText={"Extras Prices"} />
        </div>
        <div className="dashPanel" style={{ position: "relative" }}>
          <>
            <div className="ar-dashpanel">
              {err ? (
                <div
                  style={{
                    textAlign: "center",
                    color: "red",
                    position: "absolute",
                    top: "7px",
                    fontSize: "0.95rem",
                    width: "90%",
                  }}
                >
                  <p>{errMsg}</p>
                </div>
              ) : null}

              <div>
                <div className="ae-formFields">
                  <div
                    className="ae-inputField"
                    style={{ position: "relative", marginRight: "25px" }}
                  >
                    <div className="ar-selectText">Type</div>
                    <Select
                      options={typeOptions}
                      placeholder={"Select type"}
                      value={newData.carpet_type}
                      className="ae-select"
                      onChange={(data) => {
                        handleChange({
                          target: { name: "carpet_type", value: data },
                        });
                      }}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          width: "400px",
                          border: `1px solid lightgray`,
                          borderColor: "lightgray",
                          // marginLeft: "0px",
                          marginRight: "0px",
                          borderRadius: "8px",
                          fontSize: "14px",
                          padding: "0px 13px",
                        }),
                      }}
                    />
                  </div>

                  <div
                    className="ae-inputField"
                    style={{ position: "relative", marginRight: "25px" }}
                  >
                    <div className="ar-selectText">Cleaning Type</div>
                    <Select
                      options={Enums.cleaning_type_list}
                      placeholder={"Select type"}
                      value={newData.cleaning_type}
                      className="ae-select"
                      onChange={(data) => {
                        handleChange({
                          target: { name: "cleaning_type", value: data },
                        });
                      }}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          width: "400px",
                          border: `1px solid lightgray`,
                          borderColor: "lightgray",
                          // marginLeft: "0px",
                          marginRight: "0px",
                          borderRadius: "8px",
                          fontSize: "14px",
                          padding: "0px 13px",
                        }),
                      }}
                    />
                  </div>
                </div>
                <div className="ae-formFields">
                  <div
                    className="ae-inputField"
                    style={{ position: "relative", marginRight: "25px" }}
                  >
                    <div className="ar-selectText">Quantity</div>
                    <Select
                      options={
                        newData.carpet_type?.value ===
                        Enums.other_type.lounge_carpet
                          ? Enums.lounge_carpet_list
                          : newData.carpet_type?.value ===
                            Enums.other_type.room_carpet
                          ? Enums.room_carpet_list
                          : newData.carpet_type?.value ===
                            Enums.other_type.couch
                          ? Enums.couch_list
                          : Enums.mattress_list
                      }
                      placeholder={"Select type"}
                      value={
                        newData.carpet_type?.value ===
                        Enums.other_type.lounge_carpet
                          ? newData.lounge_carpet
                          : newData.carpet_type?.value ===
                            Enums.other_type.room_carpet
                          ? newData.room_carpet
                          : newData.carpet_type?.value ===
                            Enums.other_type.couch
                          ? newData.couch
                          : newData.mattress
                      }
                      className="ae-select"
                      onChange={(data) => {
                        let name =
                          newData.carpet_type?.value ===
                          Enums.other_type.lounge_carpet
                            ? "lounge_carpet"
                            : newData.carpet_type?.value ===
                              Enums.other_type.room_carpet
                            ? "room_carpet"
                            : newData.carpet_type?.value ===
                              Enums.other_type.couch
                            ? "couch"
                            : "mattress";
                        handleChange({
                          target: { name: name, value: data },
                        });
                      }}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          width: "400px",
                          border: `1px solid lightgray`,
                          borderColor: "lightgray",
                          // marginLeft: "0px",
                          marginRight: "0px",
                          borderRadius: "8px",
                          fontSize: "14px",
                          padding: "0px 13px",
                        }),
                      }}
                    />
                  </div>

                  <div className="ae-inputField">
                    <InputField
                      name={"price"}
                      type="number"
                      isErr={priceErr}
                      errorMsg={"Please fill in the field"}
                      value={newData?.price}
                      onChange={(e) => {
                        setPriceErr(false);
                        handleChange(e);
                      }}
                      cat={"Price (AUD)"}
                      placeholder={"Enter Price in AUD"}
                      radius="7px"
                      width="400px"
                    />
                  </div>
                </div>
              </div>

              <div className="addEdit-btns">
                <div className="ev-btnAdd ev-btn">
                  <button
                    className="editPage_btn"
                    style={{ backgroundColor: PRIMARY, color: "white" }}
                    disabled={isLoading}
                    onClick={saveOnclick}
                  >
                    {isLoading ? <LoadingSpinner /> : isEdit ? "Update" : "Add"}
                  </button>
                </div>
                <div className="ev-btnDel ev-btn">
                  <button
                    className="editPage_btn"
                    style={{ color: PRIMARY }}
                    disabled={isLoading}
                    onClick={() => {
                      navigate("/dashboard/other", {
                        state: { page: page },
                      });
                    }}
                  >
                    {isEdit ? "Discard" : "Back"}
                  </button>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </>
  );
}
