import { MdCleanHands } from "react-icons/md";
import { MdCleaningServices } from "react-icons/md";
import { MdDryCleaning } from "react-icons/md";
import { GiVacuumCleaner } from "react-icons/gi";
import { FaHandsWash } from "react-icons/fa";
import { TbWashGentle } from "react-icons/tb";
import { TbWashHand } from "react-icons/tb";
import { GiWashingMachine } from "react-icons/gi";
import { SiRelianceindustrieslimited } from "react-icons/si";
import { GiDustCloud } from "react-icons/gi";

const icons_bank = [
  MdCleanHands,
  MdCleaningServices,
  MdDryCleaning,
  GiVacuumCleaner,
  FaHandsWash,
  TbWashGentle,
  TbWashHand,
  GiWashingMachine,
  SiRelianceindustrieslimited,
  GiDustCloud,
];

export default icons_bank;
