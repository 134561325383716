import React from "react";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import "./App.css";
import Sidebar from "./components/Sidebar/Sidebar.jsx";
import Welcome from "./screens/Welcome";
import Login from "./screens/login/Login.jsx";
import Reviews from "./screens/reviews/Reviews.jsx";
import ContactUsers from "./screens/contactUsers/ContactUsers.jsx";
import Faq from "./screens/faq/Faq.jsx";
import FaqAddEdit from "./screens/faq/FaqAddEdit.jsx";
import ForgetPass from "./screens/forgetPassword/ForgetPass.jsx";
import ServiceCard from "./screens/serviceCards/ServiceCard.jsx";
import ServiceCardsAddEdit from "./screens/serviceCards/ServiceCardsAddEdit.jsx";
import Speciality from "./screens/speciality/Speciality.jsx";
import SpecialityAddEdit from "./screens/speciality/SpecialityAddEdit.jsx";
import Orders from "./screens/orders/Orders.jsx";
import OrdersAddEdit from "./screens/orders/OrdersAddEdit.jsx";
import PropertyPricing from "./screens/propertyPricing/PropertyPricing.jsx";
import PropertyPricingAddEdit from "./screens/propertyPricing/PropertyPricingAddEdit.jsx";
import ExtrasPricing from "./screens/extrasPricing/ExtrasPricing.jsx";
import ExtrasPricingAddEdit from "./screens/extrasPricing/ExtrasPricingAddEdit.jsx";
import OthersPricing from "./screens/othersPricing/OthersPricing.jsx";
import OtherPricingAddEdit from "./screens/othersPricing/OtherPricingAddEdit.jsx";
import Discount from "./screens/discount/discount.jsx";

function App() {
  const Dashboard = () => {
    return (
      <>
        <div style={{ display: "flex" }}>
          <Sidebar />
          <Outlet />
        </div>
      </>
    );
  };
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/forgetPass" element={<ForgetPass />} />
          <Route path="/dashboard/main" element={<Dashboard />}>
            <Route path="" element={<Welcome />} />
          </Route>
          <Route path="/dashboard" element={<Dashboard />}>
            <Route path="" element={<Welcome />} />
            <Route path="/dashboard/reviews" element={<Reviews />} />
            <Route path="/dashboard/contacts" element={<ContactUsers />} />
            <Route path="/dashboard/faq" element={<Faq />} />
            <Route path="/dashboard/faq/add" element={<FaqAddEdit />} />
            <Route path="/dashboard/faq/edit" element={<FaqAddEdit isEdit />} />
            <Route path="/dashboard/speciality" element={<Speciality />} />
            <Route
              path="/dashboard/speciality/add"
              element={<SpecialityAddEdit />}
            />
            <Route
              path="/dashboard/speciality/edit"
              element={<SpecialityAddEdit isEdit />}
            />
            <Route path="/dashboard/service-cards" element={<ServiceCard />} />
            <Route
              path="/dashboard/service-cards/add"
              element={<ServiceCardsAddEdit />}
            />
            <Route
              path="/dashboard/service-cards/edit"
              element={<ServiceCardsAddEdit isEdit />}
            />
            <Route path="/dashboard/order" element={<Orders />} />
            <Route path="/dashboard/order/view" element={<OrdersAddEdit />} />

            <Route
              path="/dashboard/property-pricing"
              element={<PropertyPricing />}
            />
            <Route
              path="/dashboard/property-pricing/add"
              element={<PropertyPricingAddEdit />}
            />
            <Route
              path="/dashboard/property-pricing/edit"
              element={<PropertyPricingAddEdit isEdit />}
            />
            <Route
              path="/dashboard/extras-pricing"
              element={<ExtrasPricing />}
            />
            <Route
              path="/dashboard/extras-pricing/add"
              element={<ExtrasPricingAddEdit />}
            />
            <Route
              path="/dashboard/extras-pricing/edit"
              element={<ExtrasPricingAddEdit isEdit />}
            />
            <Route path="/dashboard/other" element={<OthersPricing />} />
            <Route
              path="/dashboard/other/add"
              element={<OtherPricingAddEdit />}
            />
            <Route
              path="/dashboard/other/edit"
              element={<OtherPricingAddEdit isEdit />}
            />
            <Route path="/dashboard/discount" element={<Discount />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
