import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import InputField from "../../components/InputField/InputField";
import Select from "react-select";
import "animate.css";
import Header from "../../components/header/Header";
import { Pricing } from "../../SVGS";
import { PRIMARY } from "../../constants/Colors";
import { ErrorCode, ErrorMessages } from "../../constants/ErrorCodes";
import { Enums } from "../../constants/Enums";
import { postExtraPrice } from "../../api/extras";

export default function ExtrasPricingAddEdit({ isEdit }) {
  const icon = () => {
    return <Pricing size="25" fill={PRIMARY} />;
  };

  const navigate = useNavigate();
  const location = useLocation();
  const prevData =
    location.state && location.state ? location.state.editData : null;
  const page = location.state && location.state ? location.state.page : null;

  const [isLoading, setIsLoading] = useState(false);
  const [priceErr, setPriceErr] = useState(false);
  const [extraErr, setExtraErr] = useState(false);
  const [quantityErr, setquantityErr] = useState(false);
  const [quantityArray, setQuantityArray] = useState([]);
  const [showQuantitySelect, setShowQuantitySelect] = useState(false);
  const [typeOptions, settypeOptions] = useState(Enums.extras_list);
  const [err, setErr] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [newData, setNewData] = useState({
    id: prevData ? prevData.id : 0,
    extra_id: prevData
      ? Enums.extras_list.find((item) => item.value == prevData.extra_id)
      : "",
    quantity: prevData
      ? prevData.extra_id == Enums.extras.misc
        ? { value: prevData.price, label: prevData.price }
        : { value: prevData.quantity, label: prevData.quantity }
      : 1,
    price: prevData ? prevData.price : 1,
  });

  // fetching initial data
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    // let quantityArr = [];
    // for (let i = 1; i < 11; i++) {
    //   const element = { value: i, label: i };
    //   quantityArr.push(element);
    // }
    // setQuantityArray(quantityArr);
  }, []);

  useEffect(() => {
    let arr = [1, 2, 3, 4, 6, 13];
    setShowQuantitySelect(arr.includes(newData.extra_id?.value));
    if (newData?.extra_id?.value == Enums.extras.misc) createOptions();
    else if (newData?.extra_id?.value == Enums.extras.balcony)
      createQuantityOptions(3);
    else createQuantityOptions(6);
  }, [newData.extra_id]);

  function createQuantityOptions(lenght = 6) {
    let quantityArr = [];
    for (let i = 1; i <= lenght; i++) {
      const element = { value: i, label: i };
      quantityArr.push(element);
    }
    setQuantityArray(quantityArr);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewData({ ...newData, [name]: value });
  };

  function createOptions() {
    let arr = [];
    if (newData.extra_id?.value == Enums.extras.misc)
      for (let i = 50; i <= 1000; i += 50) {
        const element = { value: i, label: `$${i}` };
        arr.push(element);
      }
    else
      for (let i = 1; i < 7; i++) {
        const element = { value: i, label: i };
        arr.push(element);
      }
    setQuantityArray(arr);
  }

  const saveOnclick = () => {
    if (isValidData()) {
      let obj = {
        id: newData.id,
        extra_id: newData.extra_id.value,
        quantity: showQuantitySelect
          ? newData.extra_id.value == Enums.extras.misc
            ? 1
            : newData.quantity?.value
          : 1,
        price:
          newData.extra_id.value == Enums.extras.misc
            ? newData.quantity?.value
            : newData.price,
      };
      debugger;
      setErr(false);
      setIsLoading(true);
      postExtraPrice(obj)
        .then(({ data }) => {
          setIsLoading(false);
          if (
            data.error_code == ErrorCode.success ||
            data.error_code == ErrorCode.updated
          ) {
            navigate("/dashboard/extras-pricing", {
              state: { page: page },
            });
          } else if (data.error_code == ErrorCode.failed) {
            setErr(true);
            setErrMsg(ErrorMessages.failed);
          } else {
            setErr(true);
            setErrMsg("Oops some error occured. EC:" + data.error_code);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          setErr(true);
          setErrMsg(ErrorMessages.network_error);
        });
    }
  };

  function isValidData() {
    if (!newData.extra_id) setExtraErr(true);
    else if (!newData.price || newData.price < 0) setPriceErr(true);
    else return true;
    return false;
  }

  return (
    <>
      <div className="mainDashView">
        <div>
          <Header svg={icon} DashboardNavText={"Extras Prices"} />
        </div>
        <div className="dashPanel" style={{ position: "relative" }}>
          <>
            <div className="ar-dashpanel">
              {err ? (
                <div
                  style={{
                    textAlign: "center",
                    color: "red",
                    position: "absolute",
                    top: "7px",
                    fontSize: "0.95rem",
                    width: "90%",
                  }}
                >
                  <p>{errMsg}</p>
                </div>
              ) : null}

              <div>
                <div className="ae-formFields">
                  <div
                    className="ae-inputField"
                    style={{ position: "relative", marginRight: "25px" }}
                  >
                    <div className="ar-selectText">Type</div>
                    <Select
                      options={typeOptions}
                      placeholder={"Select type"}
                      value={newData.extra_id}
                      className="ae-select"
                      onChange={(data) => {
                        handleChange({
                          target: { name: "extra_id", value: data },
                        });
                      }}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          width: "400px",
                          border: `1px solid lightgray`,
                          borderColor: "lightgray",
                          // marginLeft: "0px",
                          marginRight: "0px",
                          borderRadius: "8px",
                          fontSize: "14px",
                          padding: "0px 13px",
                        }),
                      }}
                    />
                  </div>
                  <div
                    className="ae-inputField"
                    style={{
                      height:
                        newData.extra_id.value == Enums.extras.misc ? "0" : "",
                      overflow: "hidden",
                    }}
                  >
                    <InputField
                      name={"price"}
                      type="number"
                      isErr={priceErr}
                      errorMsg={"Please fill in the field"}
                      value={newData?.price}
                      onChange={(e) => {
                        setPriceErr(false);
                        handleChange(e);
                      }}
                      cat={"Price (AUD)"}
                      placeholder={"Enter Price in AUD"}
                      radius="7px"
                      // width="400px"
                    />
                  </div>
                </div>

                <div className="ae-formFields">
                  <div
                    className="ae-inputField"
                    style={{
                      height: showQuantitySelect ? "400px" : "0",
                      overflow: "hidden",
                      position: "relative",
                    }}
                  >
                    <div className="ar-selectText">
                      {newData.extra_id.value == Enums.extras.misc
                        ? "Price"
                        : "Quantity"}
                    </div>
                    <Select
                      options={quantityArray}
                      placeholder={
                        newData.extra_id.value == Enums.extras.misc
                          ? "Select Price"
                          : "Select quantity"
                      }
                      value={newData.quantity}
                      className="ae-select"
                      onChange={(data) => {
                        handleChange({
                          target: { name: "quantity", value: data },
                        });
                      }}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          width: "400px",
                          border: `1px solid lightgray`,
                          borderColor: "lightgray",
                          // marginLeft: "0px",
                          marginRight: "0px",
                          borderRadius: "8px",
                          fontSize: "14px",
                          padding: "0px 13px",
                        }),
                      }}
                    />
                    {/* <InputField
                      name={"quantity"}
                      type="number"
                      isErr={quantityErr}
                      errorMsg={"Please fill in the field"}
                      // value={newData?.quantity}
                      value={newData.quantity > 10 ? 10 : newData?.quantity}
                      onChange={(e) => {
                        setquantityErr(false);
                        handleChange(e);
                      }}
                      cat={"Quantity"}
                      placeholder={"Enter Quantity"}
                      radius="7px"
                      width="400px"
                    /> */}
                  </div>
                </div>
              </div>

              <div className="addEdit-btns">
                <div className="ev-btnAdd ev-btn">
                  <button
                    className="editPage_btn"
                    style={{ backgroundColor: PRIMARY, color: "white" }}
                    disabled={isLoading}
                    onClick={saveOnclick}
                  >
                    {isLoading ? <LoadingSpinner /> : isEdit ? "Update" : "Add"}
                  </button>
                </div>
                <div className="ev-btnDel ev-btn">
                  <button
                    className="editPage_btn"
                    style={{ color: PRIMARY }}
                    disabled={isLoading}
                    onClick={() => {
                      navigate("/dashboard/extras-pricing", {
                        state: { page: page },
                      });
                    }}
                  >
                    {isEdit ? "Discard" : "Back"}
                  </button>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </>
  );
}
