import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import Modal from "react-modal";
import Header from "../../components/header/Header";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { Pricing } from "../../SVGS";
import NoDataView from "../../Error/NoDataView";
import { ErrorCode, ErrorMessages } from "../../constants/ErrorCodes";
import { PRIMARY } from "../../constants/Colors";
import Select from "react-select";
import ModalComp from "../../components/modal/ModalComp";
import { FiEdit } from "react-icons/fi";
import {
  deletePropertyPrices,
  getPropertyPrices,
} from "../../api/propertyDetails";
import { Enums } from "../../constants/Enums";

export default function PropertyPricing() {
  const icon = () => {
    return <Pricing size="25" fill={PRIMARY} />;
  };

  const location = useLocation();
  const CurrPage =
    location.state && location.state ? location.state.page : null;

  const navigate = useNavigate();

  const [page, setPage] = useState(CurrPage !== null ? CurrPage : 1);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(10);
  const [data, setData] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [paginationDisplay, setPaginationDisplay] = useState("block");
  const [err, setErr] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [searchedOption, setSearchedOption] = useState("");
  const [typeOptions, settypeOptions] = useState([
    { value: -1, label: "All" },
    { value: 1, label: "Bedroom" },
    { value: 2, label: "Bathroom" },
  ]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    fetchData(page);
    Modal.setAppElement("#root");
  }, []);

  useEffect(() => {
    if (searchString !== -1) fetchData(page, searchString);
  }, [searchString]);

  const fetchData = (pageNumber, searchTxt) => {
    setErr(false);
    setisLoading(true);
    getPropertyPrices(limit, pageNumber, searchTxt)
      .then(({ data }) => {
        setisLoading(false);
        if (data.error_code == ErrorCode.success) {
          const sortedData = data.result.sort(sortData);
          setData(sortedData);
          setTotalRecords(data.total_records);
        } else if (data.error_code == ErrorCode.not_exist) {
          setData([]);
          setErrorMsg("No data found");
        } else if (data.error_code == ErrorCode.failed) {
          setErr(true);
          setErrorMsg(ErrorMessages.failed);
        } else {
          setErr(true);
          setErrorMsg(data.message);
        }
      })
      .catch((err) => {
        setErr(true);
        setErrorMsg(ErrorMessages.network_error);
        setisLoading(false);
      });
  };

  const sortData = (a, b) => {
    if (a.storey < b.storey) return -1;
    if (a.storey > b.storey) return 1;
    return 0;
  };

  const handlePageChange = (pageNumber) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (pageNumber >= 1) {
      fetchData(pageNumber, searchString);
      setPage(pageNumber);
    }
  };

  const handleSearch = (e) => {
    setSearchedOption(e);
    setSearchString(e?.value);
    if (e.value === -1) {
      fetchData(page);
    }
  };

  function setIsDeleted(id) {
    let arr = [...data.filter((item) => item.id != id)];
    setData([...arr]);
  }

  return (
    <>
      <div className="mainDashView">
        <div>
          <Header svg={icon} DashboardNavText="Property Prices" />
        </div>

        <div className="dashPanel border-lt-Gra" style={{ padding: "4% 2%" }}>
          <div className="r-ViewBar">
            <div
              className="r-ViewBar2"
              style={{
                width: "100%",
              }}
            >
              <div>
                {/* <input
                  type="search"
                  value={searchString}
                  onChange={handleSearch}
                  placeholder="Search"
                  className="rolesSearch"
                /> */}
                <Select
                  options={typeOptions}
                  placeholder={"Select to search"}
                  value={searchedOption}
                  className="ae-select"
                  onChange={(data) => {
                    handleSearch(data);
                  }}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      width: "300px",
                      border: `1px solid lightgray`,
                      borderColor: "lightgray",
                      marginRight: "0px",
                      borderRadius: "8px",
                      fontSize: "14px",
                      padding: "0px 13px",
                    }),
                  }}
                />
              </div>
              <button
                className="editPage_btn"
                style={{ backgroundColor: PRIMARY, color: "white" }}
                disabled={isLoading}
                onClick={() => {
                  navigate("/dashboard/property-pricing/add");
                }}
              >
                Add
              </button>
            </div>
          </div>
          {isLoading ? (
            <LoadingSpinner height={"40px"} width={"40px"} />
          ) : (
            <>
              {err ? (
                <div className="network-err-msg" style={{ display: "flex" }}>
                  {errorMsg}
                </div>
              ) : null}
              {data?.length > 0 ? (
                <div className="main_content_container">
                  <div className="tableContainer">
                    <table className="roleViewTable">
                      <thead>
                        <tr>
                          <th>Storey</th>
                          <th>Type</th>
                          <th>Cleaning</th>
                          <th>Quantity</th>
                          <th>Amount</th>
                          <th style={{ paddingRight: "20px" }}>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.map((data) => (
                          <Rows
                            data={data}
                            page={page}
                            setIsDeleted={(id) => setIsDeleted(id)}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div
                    className="paginationDiv"
                    style={{ display: paginationDisplay }}
                  >
                    <Pagination
                      activePage={page}
                      itemsCountPerPage={limit}
                      totalItemsCount={totalRecords}
                      pageRangeDisplayed={3}
                      onChange={(pageNumber) => {
                        handlePageChange(pageNumber);
                      }}
                    />
                  </div>
                </div>
              ) : (
                <NoDataView />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
const Rows = ({ data, page, setIsDeleted }) => {
  const navigate = useNavigate();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [imgErr, setImgErr] = useState(false);
  const [delErr, setdelErr] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const deleteOnclick = () => {
    setModalIsOpen(true);
  };

  const deleteClick = () => {
    setdelErr(false);
    let obj = {
      id: data ? data.id : null,
    };
    setIsLoading(true);
    deletePropertyPrices(obj)
      .then(({ data }) => {
        setIsLoading(false);
        if (data.error_code == ErrorCode.success) {
          setModalIsOpen(false);
          setIsDeleted(obj?.id);
        } else if (data.error_code == ErrorCode.failed) {
          setdelErr(true);
          setErrMsg(ErrorMessages.failed);
        } else {
          setdelErr(true);
          setErrMsg(data.message);
        }
      })
      .catch(() => {
        setErrMsg(ErrorMessages.network_error);
        setdelErr(true);
        setIsLoading(false);
      });
  };

  function editData() {
    navigate("/dashboard/property-pricing/edit", {
      state: { editData: data },
    });
  }

  return (
    <>
      <ModalComp
        isVisible={modalIsOpen}
        isCaution
        setIsVisible={() => {
          setdelErr(false);
          setModalIsOpen(false);
        }}
        isErr={delErr}
        errMsg={errMsg}
        onClick={deleteClick}
        isLoading={isLoading}
        msg={"Are you sure you want to delete?"}
      />
      {data ? (
        <tr>
          <td className="break-line-170">{data.storey}</td>
          <td className="break-line-170">
            {data.bedroom > 0 ? "Bedroom" : "Bathroom"}
          </td>
          <td className="break-line-170">
            {data.cleaning_type == Enums.cleaning_type.standard
              ? "Standard"
              : "Deep/Vacate"}
          </td>
          <td className="break-line-170">
            {data.bedroom > 0 ? data.bedroom : data.bathroom}
          </td>

          <td className="break-line-270">{data?.price} AUD</td>
          <td
            style={{
              display: "flex",
              justifyContent: "center",
              minWidth: "60px",
            }}
          >
            <button onClick={editData} className="Actionbtn editBtn">
              <FiEdit />
            </button>
            <button onClick={deleteOnclick} className="Actionbtn delBtn">
              <RiDeleteBin6Line />
            </button>
          </td>
        </tr>
      ) : null}
    </>
  );
};
