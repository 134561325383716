import React from "react";

const EmployeeSVG = (props) => {
  return (
    <svg
      width={props.size ? props.size : "19"}
      height={props.size ? props.size : "19"}
      viewBox="0 0 19 19"
      fill={props.fill ? props.fill : "9698AB"}
      xmlns="http://www.w3.org/2000/svg"
    >
      {" "}
      <path
        d="M12.2803 16.6287C9.74098 16.6287 7.25458 16.6287 4.71529 16.6287C4.71529 16.5758 4.71529 16.4965 4.71529 16.4436C4.71529 15.2533 4.71529 14.0895 4.71529 12.8992C4.71529 11.0211 5.93203 9.51343 7.75715 9.11667C9.82033 8.667 11.9364 10.1218 12.2538 12.2114C12.2803 12.423 12.3067 12.6611 12.3067 12.8727C12.3067 14.063 12.3067 15.2533 12.3067 16.4171C12.2803 16.4965 12.2803 16.5494 12.2803 16.6287Z"
        fill={props.fill ? props.fill : "#9698AB"}
      />{" "}
      <path
        d="M4.34457 13.3753C2.88977 13.3753 1.46142 13.3753 0.0330637 13.3753C0.0330637 13.3488 0.00661276 13.3224 0.00661276 13.2959C0.00661276 11.9734 -0.0198382 10.6508 0.0330637 9.32828C0.0859657 7.68832 1.30271 6.31287 2.88977 5.91611C3.6833 5.73095 4.42393 5.78385 5.191 6.07481C5.34971 6.12771 5.42906 6.20707 5.45551 6.39222C5.61422 7.50316 6.22259 8.27024 7.20128 8.77281C7.22773 8.79926 7.28063 8.79926 7.33353 8.82571C5.24391 9.61924 4.26522 11.1534 4.34457 13.3753Z"
        fill={props.fill ? props.fill : "#9698AB"}
      />{" "}
      <path
        d="M12.6495 13.3767C12.7289 11.1548 11.7502 9.62065 9.66055 8.82712C9.71345 8.80067 9.7399 8.77422 9.7928 8.77422C10.8244 8.27165 11.4328 7.42521 11.565 6.28782C11.565 6.20847 11.6708 6.12912 11.7237 6.10267C12.8347 5.653 13.9456 5.67945 15.0036 6.26137C16.2997 6.97555 16.961 8.11294 16.9875 9.62065C17.0139 10.8109 16.9875 12.0277 16.9875 13.218C16.9875 13.2709 16.9875 13.3238 16.961 13.3767C15.5062 13.3767 14.1043 13.3767 12.6495 13.3767Z"
        fill={props.fill ? props.fill : "#9698AB"}
      />{" "}
      <path
        d="M5.77244 5.96929C5.77244 4.48803 6.98919 3.27129 8.49689 3.27129C9.97815 3.27129 11.1949 4.48803 11.1949 5.99574C11.1949 7.47699 9.9517 8.69374 8.47044 8.69374C6.98919 8.69374 5.77244 7.47699 5.77244 5.96929Z"
        fill={props.fill ? props.fill : "#9698AB"}
      />{" "}
      <path
        d="M3.5781 5.44111C2.38781 5.44111 1.19752 4.33017 1.09171 2.95472C0.985909 1.55282 1.9646 0.283172 3.3665 0.0715646C4.39809 -0.0871413 5.27097 0.256722 5.93224 1.0767C6.56707 1.89668 6.69932 2.79602 6.30256 3.7747C6.19675 4.01276 6.0116 4.22437 5.85289 4.43598C5.72064 4.59468 5.58838 4.77984 5.42967 4.9121C4.95356 5.28241 4.39809 5.46757 3.5781 5.44111Z"
        fill={props.fill ? props.fill : "#9698AB"}
      />{" "}
      <path
        d="M13.3653 5.44065C12.5717 5.44065 12.0163 5.2555 11.5401 4.85873C11.3814 4.72648 11.2756 4.56777 11.1434 4.40907C11.0905 4.32971 11.0376 4.25036 10.9847 4.171C10.2969 3.27167 10.3498 2.31944 10.8524 1.39365C11.3814 0.388514 12.5717 -0.166956 13.6562 0.0446517C14.8465 0.282711 15.6929 1.15559 15.8781 2.34589C16.0897 4.0123 14.8201 5.4671 13.3653 5.44065Z"
        fill={props.fill ? props.fill : "#9698AB"}
      />{" "}
    </svg>
  );
};

export default EmployeeSVG;
