export const Enums = {
  recieve_content: {
    no: 0,
    yes: 1,
  },
  is_home: {
    no: 0,
    yes: 1,
  },
  property_type: {
    un_furnished: 0,
    furnished: 1,
  },
  is_power_available: {
    no: 0,
    yes: 1,
  },

  discount_type: {
    property: 1,
    other: 2,
  },
  cleaning_type: {
    standard: 1,
    deep: 2,
  },
  service_cards: {
    home_offer_cards: 0,
    sevices_cleaning_types: 1,
    sevices_qualities: 2,
  },
  extras: {
    balcony: 1,
    wall_wash: 2,
    window: 3,
    blinds: 4,
    garage: 5,
    misc: 6,
    fidge: 7,
    pressure_washing: 8,
    double_fridge: 9,
    travel_fee: 10,
    living_area: 11,
    urgent_job: 12,
    carpet_steam_clean: 13,
  },
  other_type: {
    lounge_carpet: 1,
    room_carpet: 2,
    couch: 3,
    mattress: 4,
  },
  other_type_labels: ["Lounge carpet", "Room carpet", "Couch", "Mattress"],
  extras_list: [
    { value: 1, label: "Additional Balcony" },
    { value: 2, label: "Wall wash" },
    { value: 3, label: "Outside Window Clean" },
    { value: 4, label: "Blinds" },
    { value: 5, label: "Garage Clean" },
    // { value: 6, label: "Misc" },
    { value: 7, label: "Fridge" },
    { value: 8, label: "Pressure Washing" },
    { value: 9, label: "Double Fridge" },
    // { value: 10, label: "Travel Fee" },
    { value: 11, label: "Living Area" },
    // { value: 12, label: "Urgent Job" },
    // { value: 13, label: "Carpet Steam Clean" },
  ],
  others_list: [
    { value: 1, label: "Lounge carpet" },
    { value: 2, label: "Room carpet" },
    { value: 3, label: "Couch" },
    { value: 4, label: "Mattress" },
  ],
  cleaning_type_list: [
    { value: 1, label: "Standard" },
    { value: 2, label: "Deep" },
  ],
  couch_list: [
    { value: 1, label: "1 Seat" },
    { value: 2, label: "2 Seats" },
    { value: 3, label: "3 Seats" },
    { value: 4, label: "4 Seats" },
    { value: 5, label: "5 Seats" },
    { value: 6, label: "6 Seats" },
    { value: 7, label: "7 Seats" },
    { value: 8, label: "8 Seats" },
    { value: 9, label: "9 Seats" },
    { value: 10, label: "10 Seats" },
  ],
  mattress_list: [
    { value: 1, label: "1 Mattress" },
    { value: 2, label: "2 Mattresses" },
    { value: 3, label: "3 Mattresses" },
    { value: 4, label: "4 Mattresses" },
    { value: 5, label: "5 Mattresses" },
    { value: 6, label: "6 Mattresses" },
    { value: 7, label: "7 Mattresses" },
  ],
  room_carpet_list: [
    { value: 1, label: "1 Room Carpet" },
    { value: 2, label: "2 Room Carpets" },
    { value: 3, label: "3 Room Carpets" },
    { value: 4, label: "4 Room Carpets" },
    { value: 5, label: "5 Room Carpets" },
    { value: 6, label: "6 Room Carpets" },
    { value: 7, label: "7 Room Carpets" },
  ],
  lounge_carpet_list: [
    { value: 1, label: "1 Lounge Carpet" },
    { value: 2, label: "2 Lounge Carpets" },
    { value: 3, label: "3 Lounge Carpets" },
    { value: 4, label: "4 Lounge Carpets" },
    { value: 5, label: "5 Lounge Carpets" },
    { value: 6, label: "6 Lounge Carpets" },
    { value: 7, label: "7 Lounge Carpets" },
  ],
};
