import React from "react";

export default function StockSVG({
  height = "15",
  width = "14",
  size = "15",
  fill = "#8B8EA8",
}) {
  return (
    <svg
      width={size ? size : width}
      height={size ? size : height}
      viewBox="0 0 14 15"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.9082 3.32955C7.9082 1.4798 9.28282 0 10.9747 0C12.6453 0 13.9988 1.50292 13.9988 3.32955C13.9988 5.17929 12.6242 6.65909 10.9324 6.65909C9.26167 6.65909 7.9082 5.17929 7.9082 3.32955ZM10.9747 0.508682C9.55774 0.48556 8.39461 1.75726 8.37346 3.30642C8.37346 4.85559 9.51544 6.12729 10.9324 6.15041C12.3493 6.15041 13.5124 4.90183 13.5335 3.35267C13.5335 1.82663 12.3916 0.531803 10.9747 0.508682Z"
        fill={fill}
      />
      <path
        d="M13.0496 6.4502C13.0496 9.24794 13.0496 12.0457 13.0496 14.8665C12.7324 14.8665 12.4364 14.8665 12.1191 14.8665C12.1191 14.8203 12.1191 14.7509 12.1191 14.7047C12.1191 12.1844 12.1191 9.66413 12.1191 7.12073C12.1191 7.00512 12.1403 6.95887 12.246 6.88951C12.5209 6.75078 12.7747 6.58893 13.0496 6.4502Z"
        fill={fill}
      />
      <path
        d="M11.6317 14.8676C11.3356 14.8676 11.0184 14.8676 10.7012 14.8676C10.7012 14.8213 10.7012 14.752 10.7012 14.7057C10.7012 12.5323 10.7012 10.3819 10.7012 8.20848C10.7012 8.13912 10.7435 8.02351 10.7858 7.97726C11.0184 7.72292 11.251 7.46858 11.4836 7.21424C11.5259 7.168 11.5682 7.14488 11.6317 7.09863C11.6317 9.68828 11.6317 12.2779 11.6317 14.8676Z"
        fill={fill}
      />
      <path
        d="M10.1721 7.12161C9.93952 7.37595 9.68575 7.65341 9.43197 7.90775C9.38968 7.93088 9.32623 7.954 9.28393 7.954C8.73409 7.954 8.20539 7.954 7.65554 7.954C7.57095 7.954 7.50751 7.97712 7.44406 8.06961C6.95766 8.67078 6.47126 9.27194 6.00601 9.87311C5.92142 9.9656 5.83682 10.0118 5.70993 10.0118C4.88516 10.0118 4.03925 10.0118 3.21448 10.0118C3.10874 10.0118 3.0453 10.035 2.98186 10.1275C2.13594 11.1448 1.26887 12.1622 0.422958 13.1795C0.40181 13.2027 0.380659 13.2489 0.359511 13.2951C0.232623 13.1564 0.10574 13.0408 0 12.9252C0.190331 12.694 0.401808 12.4628 0.592139 12.2315C1.33232 11.376 2.05134 10.4974 2.79152 9.64189C2.85496 9.54941 2.93956 9.52628 3.0453 9.52628C3.89122 9.52628 4.73713 9.52628 5.58305 9.52628C5.66764 9.52628 5.73108 9.50316 5.79453 9.41067C6.28093 8.80951 6.76734 8.20834 7.25374 7.58405C7.31718 7.49156 7.40176 7.44532 7.5075 7.44532C8.0362 7.44532 8.5649 7.44532 9.0936 7.44532C9.19934 7.44532 9.26279 7.4222 9.32623 7.32971C9.34738 7.30659 9.36853 7.28347 9.36853 7.28347C9.55886 6.91352 9.83378 6.86727 10.1721 7.12161Z"
        fill={fill}
      />
      <path
        d="M10.2355 14.8673C9.91831 14.8673 9.62224 14.8673 9.32617 14.8673C9.32617 12.9019 9.32617 10.9597 9.32617 8.99432C9.34732 8.99432 9.34731 8.9712 9.34731 8.9712C9.5165 8.9712 9.70683 9.01744 9.85487 8.94807C10.0029 8.87871 10.1086 8.71686 10.2355 8.57812C10.2355 10.6822 10.2355 12.7632 10.2355 14.8673Z"
        fill={fill}
      />
      <path
        d="M7.9082 8.97168C8.22542 8.97168 8.52149 8.97168 8.81756 8.97168C8.81756 10.937 8.81756 12.8793 8.81756 14.8446C8.52149 14.8446 8.20427 14.8446 7.9082 14.8446C7.9082 12.9024 7.9082 10.9602 7.9082 8.97168Z"
        fill={fill}
      />
      <path
        d="M7.4227 14.8682C7.10548 14.8682 6.80941 14.8682 6.49219 14.8682C6.49219 14.8219 6.49219 14.7526 6.49219 14.7063C6.49219 13.4809 6.49219 12.2323 6.49219 11.0068C6.49219 10.9143 6.51334 10.8219 6.57678 10.7525C6.85171 10.3363 7.12663 9.94322 7.4227 9.50391C7.4227 11.3074 7.4227 13.0647 7.4227 14.8682Z"
        fill={fill}
      />
      <path
        d="M3.70117 11.0303C3.99724 11.0303 4.31447 11.0303 4.61054 11.0303C4.61054 12.302 4.61054 13.5737 4.61054 14.8454C4.31447 14.8454 3.99724 14.8454 3.70117 14.8454C3.70117 13.5968 3.70117 12.3251 3.70117 11.0303Z"
        fill={fill}
      />
      <path
        d="M6.00506 14.8685C5.68784 14.8685 5.39177 14.8685 5.0957 14.8685C5.0957 13.5968 5.0957 12.3251 5.0957 11.0303C5.39177 11.0303 5.70899 11.0303 6.00506 11.0303C6.00506 12.302 6.00506 13.5737 6.00506 14.8685Z"
        fill={fill}
      />
      <path
        d="M3.19419 14.8673C2.89811 14.8673 2.58089 14.8673 2.26367 14.8673C2.26367 14.7286 2.26367 14.613 2.26367 14.4743C2.26367 13.8962 2.26367 13.3182 2.26367 12.7401C2.26367 12.6708 2.26367 12.6014 2.30597 12.5552C2.58089 12.1852 2.87697 11.8384 3.19419 11.4453C3.19419 12.6014 3.19419 13.7344 3.19419 14.8673Z"
        fill={fill}
      />
      <path
        d="M1.7975 14.8682C1.37454 14.8682 0.951582 14.8682 0.486328 14.8682C0.930434 14.3133 1.3534 13.7815 1.7975 13.2266C1.7975 13.7815 1.7975 14.3133 1.7975 14.8682Z"
        fill={fill}
      />
      <path
        d="M10.9546 1.04004C12.1178 1.04004 13.0694 2.08052 13.0694 3.35222C13.0694 4.62392 12.1178 5.66441 10.9546 5.66441C9.7915 5.66441 8.83984 4.62392 8.83984 3.35222C8.83984 2.0574 9.77035 1.04004 10.9546 1.04004ZM10.722 4.62392C10.722 4.8089 10.722 4.97075 10.722 5.1326C10.8912 5.1326 11.0392 5.1326 11.1873 5.1326C11.1873 4.97075 11.1873 4.8089 11.1873 4.64705C11.2296 4.64705 11.2296 4.64705 11.2507 4.62392C11.6525 4.53144 11.9063 4.18461 11.8851 3.79154C11.8428 3.35222 11.5468 3.09788 11.1238 3.07476C10.9969 3.07476 10.8489 3.07476 10.722 3.07476C10.5951 3.07476 10.5105 2.98227 10.5105 2.84354C10.4894 2.70481 10.574 2.5892 10.6797 2.56608C10.9335 2.54296 11.1873 2.56608 11.441 2.56608C11.441 2.65857 11.441 2.72793 11.4622 2.7973C11.6314 2.7973 11.7794 2.7973 11.9063 2.7973C11.9063 2.54296 11.9063 2.28862 11.9063 2.03428C11.6737 2.03428 11.441 2.03428 11.2084 2.03428C11.2084 1.8493 11.2084 1.68745 11.2084 1.54872C11.0392 1.54872 10.8912 1.54872 10.7432 1.54872C10.7432 1.71057 10.7432 1.87243 10.7432 2.03428C10.722 2.03428 10.7009 2.03428 10.6797 2.0574C10.2779 2.14989 10.003 2.47359 10.0453 2.86667C10.0876 3.30598 10.3836 3.58344 10.8277 3.58344C10.9546 3.58344 11.1027 3.58344 11.2296 3.58344C11.3564 3.58344 11.441 3.69905 11.441 3.83778C11.441 3.97651 11.3564 4.09212 11.2507 4.09212C11.0181 4.09212 10.7643 4.09212 10.5105 4.09212C10.5105 3.99964 10.5105 3.93027 10.4894 3.83778C10.3202 3.83778 10.1933 3.83778 10.0453 3.83778C10.0453 4.09212 10.0453 4.34646 10.0453 4.60081C10.2567 4.62393 10.4682 4.62392 10.722 4.62392Z"
        fill={fill}
      />
    </svg>
  );
}
