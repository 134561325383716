import React from "react";
import "./style.css";
import "animate.css";
import Header from "../components/header/Header";

function Welcome() {
  return (
    <>
      <div className="mainDashView">
        <Header />

        <div className="dashPanel mainDashPanel">
          <div className="mainContent animate__animated animate__zoomIn">
            <div className="md_graph">
              <span className="welcome_text">Welcome to AWS PCS CMS</span>
              <span className="owner_text">
                Powered by <i>digiSofTech 360</i>{" "}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Welcome;
